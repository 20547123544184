/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #004b91;
// $success: #98c23c;
// $danger: #d2232a;
$warning: #dac803;

$navbar-height: 7vh;
$footer-height: 16vh;

.theme-navbar {
    min-height: $navbar-height;
    background-color: #818185;
    color: #fff;

    // .nav-item {
    //     border-bottom: solid 3px #dac803;
    // }

    .nav-link {
        color: #fff !important;
    }

    .nav-item:hover .nav-link {
        color: #000 !important;
    }

    .nav-item:hover {
        text-decoration: none;
        background-color: #dac803;
        border-radius: 3px;
    }
}

#wrapper {
    min-height: 100 - $navbar-height - $footer-height;
}

.theme-footer {
    min-height: $footer-height;
    background-color: #3f4248;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        color: #dac803;
    }
}

.brand-logo {
    height: auto;
    width: 80px;
}

.swiper-pagination-bullet-active {
  background-color: black !important;
}
.swiper-button-prev {
  color: black !important;
}
.swiper-button-next {
  color: black !important;
}
.swiper-wrapper{
    height: 94% !important; 
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"